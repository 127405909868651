import React, {
  useEffect,
  useLayoutEffect,
  useState,
  useCallback,
  useRef,
} from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  InputAdornment,
} from "@mui/material";
import moment from "moment";
import styles from "../therapy-tests/index.module.scss";
import client from "../../services/core/limboclient";
import SearchTwoToneIcon from "@mui/icons-material/SearchTwoTone";
import ClearTwoToneIcon from "@mui/icons-material/ClearTwoTone";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import AppointmentModal from "./components/appointment-modal";

const columns = [
  { id: "name", label: "Ім'я" },
  { id: "phone", label: "Номер телефону" },
  { id: "howToCall", label: "Обраний спосіб комунікації" },
  { id: "referrer", label: "Як дізнались" },
  { id: "createDate", label: "Дата створення заявки" },
];

const TherapyAppointmentUnassignedPage = () => {
  const tableEl = useRef();
  const [activeTab, setActiveTab] = useState(0);
  const [appointmentList, setAppointmentList] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [distanceBottom, setDistanceBottom] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [isOpenAppointmentModal, setIsOpenAppointmentModal] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [appointmentCounts, setAppointmentCounts] = useState({
    new: 0,
    processed: 0,
    declined: 0,
  });

  const fetchAppointmentCounts = useCallback(() => {
    client
      .call(
        `=> appointment_getAppointmentCounts ~ ${JSON.stringify({
          dateFrom: dateFrom ? moment(dateFrom).format("DD.MM.YYYY") : null,
          dateTo: dateTo ? moment(dateTo).format("DD.MM.YYYY") : null,
        })}`
      )
      .then((result) => {
        if (result.length !== null) {
          const counts = result.reduce(
            (acc, { status, qty }) => {
              switch (status.val) {
                case 0:
                  acc.new = qty;
                  break;
                case 1:
                  acc.processed = qty;
                  break;
                case -1:
                  acc.declined = qty;
                  break;
                default:
                  break;
              }
              return acc;
            },
            { new: 0, processed: 0, declined: 0 }
          );

          setAppointmentCounts(counts);
        }
      });
  }, [dateFrom, dateTo]);

  const loadMore = useCallback(() => {
    const loadAppointments = () => {
      if (hasMore) {
        client
          .call(
            `=> appointment_getAppointments ~ ${JSON.stringify({
              status: Number(activeTab),
              phrase: searchQuery,
              page: page + 1,
              dateFrom: dateFrom ? moment(dateFrom).format("DD.MM.YYYY") : null,
              dateTo: dateTo ? moment(dateTo).format("DD.MM.YYYY") : null,
            })}`
          )
          .then((result) => {
            if (result.length) {
              setAppointmentList([...appointmentList, ...result]);
              setPage(page + 1);
              if (result?.length === 0) {
                setHasMore(false);
              }
              setLoading(false);
            }
          });
      }
    };
    setLoading(true);
    loadAppointments();
  }, [appointmentList, searchQuery, dateFrom, dateTo, activeTab]);

  const scrollListener = useCallback(() => {
    let bottom = tableEl.current.scrollHeight - tableEl.current.clientHeight;
    if (!distanceBottom) {
      setDistanceBottom(Math.round(bottom * 0.2));
    }
    if (
      tableEl.current.scrollTop > bottom - distanceBottom &&
      hasMore &&
      !loading
    ) {
      loadMore();
    }
  }, [hasMore, loadMore, loading, distanceBottom]);

  useLayoutEffect(() => {
    const tableRef = tableEl.current;
    tableRef.addEventListener("scroll", scrollListener);
    return () => {
      tableRef.removeEventListener("scroll", scrollListener);
    };
  }, [scrollListener]);

  useEffect(() => {
    if (!isOpenAppointmentModal) {
      client
        .call(
          `=> appointment_getAppointments ~ ${JSON.stringify({
            status: Number(activeTab),
            phrase: searchQuery,
            page: 1,
            dateFrom: dateFrom ? moment(dateFrom).format("DD.MM.YYYY") : null,
            dateTo: dateTo ? moment(dateTo).format("DD.MM.YYYY") : null,
          })}`
        )
        .then((result) => {
          if (result.length !== null) {
            setAppointmentList(result);
          }
        });
      setPage(1);
      setHasMore(true);
      fetchAppointmentCounts();
    }
  }, [
    activeTab,
    isOpenAppointmentModal,
    searchQuery,
    dateFrom,
    dateTo,
    fetchAppointmentCounts,
  ]);

  useEffect(() => {
    const table = document.getElementById("table");
    if (table) table.scrollTop = 0;
  }, [activeTab]);

  const handleCellView = (value, columnType) => {
    switch (columnType) {
      case "createDate":
        return moment(new Date(value)).locale("uk").format("LLL");
      case "howToCall":
        return value === "viber" ? (
          <div className='flex items-center'>
            <p className='text-gray'>Viber</p>
          </div>
        ) : value === "telegram" ? (
          <div className='flex items-center'>
            <p className='text-gray'>Telegram</p>
          </div>
        ) : value === "whatsUp" ? (
          <div className='flex items-center'>
            <p className='text-gray'>WhatsApp</p>
          </div>
        ) : (
          <div className='flex items-center'>
            <p className='text-gray'>Дзвінок</p>
          </div>
        );
      case "referrer":
        return value ? value : "Невідомо";
      default:
        return value;
    }
  };

  return (
    <div>
      <div className='flex items-center justify-between'>
        <ToggleButtonGroup
          color='primary'
          key={activeTab}
          value={activeTab}
          exclusive
          onChange={(event, viewName) => {
            if (viewName !== null) {
              setActiveTab(viewName);
            }
          }}
          aria-label='Platform'
          className='w-[100%]'
        >
          <ToggleButton
            value={0}
            className='!normal-case'
          >
            Нові заявки ({appointmentCounts.new})
          </ToggleButton>
          <ToggleButton
            value={1}
            className='!normal-case'
          >
            Прийняті заявки ({appointmentCounts.processed})
          </ToggleButton>
          <ToggleButton
            value={-1}
            className='!normal-case'
          >
            Відхилені заявки ({appointmentCounts.declined})
          </ToggleButton>
        </ToggleButtonGroup>
        <div className='flex items-center'>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              label='Від'
              value={dateFrom}
              onChange={(newValue) => setDateFrom(newValue)}
              className='w-[150px] !ml-5'
              format='DD.MM.YYYY'
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              label='До'
              value={dateTo}
              onChange={(newValue) => setDateTo(newValue)}
              className='w-[150px] !ml-5'
              format='DD.MM.YYYY'
            />
          </LocalizationProvider>
        </div>
        <TextField
          variant='outlined'
          value={searchQuery}
          className='w-[600px]'
          onChange={(event) => setSearchQuery(event.target.value)}
          placeholder="Пошук за ім'ям або телефоном..."
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <SearchTwoToneIcon color='primary' />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position='end'>
                <ClearTwoToneIcon
                  className='cursor-pointer'
                  color='error'
                  onClick={() => setSearchQuery("")}
                />
              </InputAdornment>
            ),
          }}
        />
      </div>
      <div className='mt-5 overflow-hidden relative'>
        <TableContainer
          sx={{
            maxHeight: "65vh",
            height: "100%",
            width: "100%",
            position: "relative",
            marginBottom: "20px",
            marginTop: "10px",
          }}
          ref={tableEl}
          id='table'
        >
          <Table
            stickyHeader
            aria-labelledby='tableTitle'
            size='medium'
          >
            <TableHead>
              <TableRow>
                {appointmentList?.length > 0 &&
                  columns.map((item) => (
                    <TableCell
                      key={item.id}
                      align='left'
                      style={{ fontWeight: "bold" }}
                    >
                      {item.label}
                    </TableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {appointmentList &&
                appointmentList.map((row, index) => (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={index.toString()}
                    className='cursor-pointer'
                    onClick={() => {
                      setSelectedAppointment(row);
                      setIsOpenAppointmentModal(true);
                    }}
                  >
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align='left'
                        className={styles.rowItemContainer}
                      >
                        {handleCellView(row[column.id], column.id)}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {appointmentList?.length === 0 && (
        <em className='opacity-60 flex items-center justify-center text-xl'>
          Відсутні заявки для відображення...
        </em>
      )}
      <AppointmentModal
        isOpen={isOpenAppointmentModal}
        selectedAppointment={selectedAppointment}
        onClose={() => setIsOpenAppointmentModal(false)}
      />
    </div>
  );
};

export default TherapyAppointmentUnassignedPage;
